// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.guess {
    --size: 44px;
    font-size: 24px;
    display: block;
    margin: auto;
    text-align: center;
    font-family: "Clear Sans","Helvetica Neue",Arial,sans-serif;
    div {
        border: solid black;
        border-width: 2px 1px 2px 1px;
        user-select: none;
        width: var(--size);
        height: var(--size);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        color: white;
        background-color: #787c7e;
        span {
            display: inline-block;
            line-height: 1;
        }
    }
    div:first-child {
        border-left-width: 2px;
    }
    div:last-child {
        border-right-width: 2px;
    }

    .green {
        background-color: #6aaa64;
    }
    .yellow {
        background-color: #c9b458;
    }
}

.wrapper {
    max-width: 900px!important;
}

html, body {
    margin: 0; padding: 0;
}

.post-content {
    h2 {
        margin-top: 0;
    }
    h3 {
        margin-top: 0;
    }
}

video {
    width:80%;
    margin: auto;
    display: block
}

.myAnchor {
    color: inherit!important;
    text-decoration: none!important;
    font-size: 0.7em!important;
    vertical-align: middle;
}

.myAnchor:hover {
    color: #666!important;
}

@media screen and (min-width: $on-medium) {
    .footer-col:last-child {
      text-align: right;
    }
  }